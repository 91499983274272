import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import col1 from '../Assets/col1.webp';
import col2 from '../Assets/col2.webp';
import col3 from '../Assets/col3.webp';
import col4 from '../Assets/col4.webp';
import col5 from '../Assets/col5.webp';
import col6 from '../Assets/col6.webp';
import col7 from '../Assets/col7.webp';

const Collection = () => {
  const navigate = useNavigate();
  const [navigateTrigger, setNavigateTrigger] = useState(false);

  const handleExploreClick = (e) => {
    navigate('/categories');
    e.stopPropagation(); // Prevent click from going to the parent div
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };
  
  const handleProductsClick = () => {
    navigate('/products');
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  // Effect to handle navigation and scroll reset
  React.useEffect(() => {
    if (navigateTrigger) {
      navigate('/categories', { replace: true }); // Force fresh navigation
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setNavigateTrigger(false); // Reset the trigger
      }, 0);
    }
  }, [navigateTrigger, navigate]);

  return (
    <div className="w-full overflow-x-hidden mt-0 pt-0 cursor-pointer">
      <div className="w-full overflow-x-hidden mb-4 pt-0"
      onClick={handleProductsClick}>
        <h1 className="font-gothamBold text-[#3D291D] text-center mb-12">Our Collection</h1>

        {/* Desktop layout */}
        <div className="hidden md:flex flex-col md:flex-row space-x-2">
          <div className="flex flex-col md:w-2/3">
            <img src={col1} alt="Collection Image 1" className="w-full h-auto object-cover mb-2" />
            <div className="flex space-x-2">
              <img src={col4} alt="Collection Image 4" className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100" />
              <img src={col5} alt="Collection Image 5" className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100" />
              <img src={col6} alt="Collection Image 6" className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100" />
              <img src={col7} alt="Collection Image 7" className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100" />
            </div>
          </div>

          <div className="flex flex-col w-[34%] h-[80%] mr-2">
            <img src={col2} alt="Collection Image 2" className="w-full h-full object-cover" />
          </div>

          <img src={col3} alt="Collection Image 3" className="w-1/3 h-auto object-cover md:scale-[0.9] lg:scale-100" />
        </div>

        {/* Mobile layout */}
        <div className="block md:hidden mt-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between gap-1">
              <img src={col2} alt="Collection Image 2" className="w-[49%] h-auto object-cover" />
              <img src={col3} alt="Collection Image 3" className="w-[49%] h-auto object-cover" />
            </div>
            <div className="flex flex-row justify-between gap-1">
              <img src={col1} alt="Collection Image 1" className="w-[49%] h-auto object-cover" />
              <img src={col5} alt="Collection Image 5" className="w-[49%] h-auto object-cover" />
            </div>
            <div className="flex flex-row justify-between gap-1">
              <img src={col6} alt="Collection Image 6" className="w-[49%] h-auto object-cover" />
              <img src={col7} alt="Collection Image 7" className="w-[49%] h-auto object-cover" />
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={handleExploreClick}
            className="
              text-[#3D291D]
              font-gothamBold
              text-xl md:text-2xl
              border-3
              border-[#3D291D]
              rounded-full
              px-4 py-1 md:px-6 md:py-2
              transition
              duration-300
              no-underline
              decoration-none
              hover:bg-[#3D291D]
              hover:text-white
            "
          >
            Explore
          </button>
        </div>
    
      </div>
    </div>
  );
};

export default Collection;
