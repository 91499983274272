import React, { useEffect, useState, useRef } from 'react';
import './signup.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import backgroundImageDesktop from '../../Assets/saarconcept-logo.webp'; // WebP image file
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsappContact from '../../components/contact';
import Navbar from '../../components/navbar';

const Signup = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+90');
  // const [showWaitlist, setShowWaitlist] = useState(true); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    country: '',
    password: '',
    password2: ''
  });
  const formRef = useRef(null);

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const countryList = data.map(country => ({
          name: country.name.common,
          code: country.cca2,
          dialCode: country.idd?.root + (country.idd?.suffixes?.[0] || '')
        })).filter(country => country.dialCode);
        const sortedCountryList = countryList.sort((a, b) => a.name.localeCompare(b.name));

        setCountries(sortedCountryList);
     
      });
  }, []);

  const handleCountryCodeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountryCode(selectedOption.dialCode);
      setFormData(prevState => ({
        ...prevState,
        country: selectedOption.label
      }));
    } else {
      setSelectedCountryCode('+90');
      setFormData(prevState => ({
        ...prevState,
        country: ''
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

 // Counter to track submission attempts
let submissionCount = 0;
const handleSignupSubmit = (e) => {
  e.preventDefault();

  // Increment the counter each time the form is submitted
  submissionCount++;
  console.log(`Form submitted ${submissionCount} times`);

  // Validation
  if (formData.password !== formData.password2) {
    toast.error('Passwords do not match. Please try again.');
    return;
  }

  if (formData.password.length < 8) {
    toast.error('Password must be at least 8 characters long.');
    return;
  }

  if (/^\d+$/.test(formData.password)) {
    toast.error('Password cannot be entirely numeric.');
    return;
  }

  if (formData.email.indexOf('@') === -1 || formData.email.indexOf('.') === -1) {
    toast.error('Invalid email address.');
    return;
  }

  const payload = {
    email: formData.email,
    first_name: formData.firstName,
    last_name: formData.lastName,
    phone: selectedCountryCode + formData.phone.replace(/\s+/g, ''), // Remove spaces
    country: formData.country || '',
    password: formData.password,
    password2: formData.password2
  };

  fetch('https://saarconcept.net/api/signup/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      console.log('Response Status:', response.status);
      console.log('Response Headers:', response.headers);
      return response.json(); // Use json() to parse response
    })
    .then(data => {
      console.log('Parsed Response Data:', data);

      if (data.message === 'User created successfully') {
        toast.success('Signup successful!');

        // Store the token after successful signup (if it's returned in the response)
        if (data.token) {
          localStorage.setItem('authToken', data.token); // Store token in localStorage
        }

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        let errorMessage = 'Signup failed:\n';

        if (data.phone && Array.isArray(data.phone)) {
          errorMessage += `Phone: ${data.phone.join(', ')}\n`;
        }
        if (data.email && Array.isArray(data.email)) {
          errorMessage += `Email: ${data.email.join(', ')}\n`;
        }
        if (data.password && Array.isArray(data.password)) {
          errorMessage += `Password: ${data.password.join(', ')}\n`;
        }
        if (data.non_field_errors && Array.isArray(data.non_field_errors)) {
          errorMessage += `Errors: ${data.non_field_errors.join(', ')}\n`;
        }
        if (data.message) {
          errorMessage += `Message: ${data.message}\n`;
        }

        if (errorMessage === 'Signup failed:\n') {
          errorMessage += 'An unknown error occurred. Please try again.';
        }

        toast.error(errorMessage.trim());
      }
    })
    .catch(error => {
      console.error('Network Error:', error);
      toast.error(`Network error: ${error.message}`);
    });
};

  
  const handleSubmitButtonClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const customStylesCountryCode = {
    control: (provided, state) => ({
      ...provided,
      minWidth: '100px',
      color: '#EED8D1',
      backgroundColor: 'transparent',
      borderColor: '#EED8D1',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0px',
      fontSize:'13px',
      borderBottomColor: '#EED8D1',
      boxShadow: 'none',
      '&:hover': {
        borderBottomColor: '#EED8D1',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#EED8D1',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#EED8D1',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#EED8D1',
      backgroundColor: state.isSelected ? '#EED8D1' : '#0F2B2A',
      '&:hover': {
        backgroundColor: '#1E3A34',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#0F2B2A',
      borderRadius: '0px',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: '#0F2B2A',
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#EED8D1',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };
  // const handleJoinClick = () => {
  //   setShowWaitlist(false); 
  //   console.log('User joined the waitlist');
  // };
  
  // const handleWaitClick = () => {

  //   console.log('Show waitlist form');
  // };
  
  return (
<>

    <div className="signup-container">
    <Navbar className="signup-navbar" />
      <img src={backgroundImageDesktop} alt="Background" className="background-image-desktop-signup" />

      <div className="signup-content">
        <button className="signup-button" type="button" onClick={() => navigate('/login')}>Log In</button>
        <h3 className="signup-heading">Sign Up</h3>
        <div className="signup-form">
          <form ref={formRef} onSubmit={handleSignupSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <div className="select-wrapper">
              <Select
                options={countries.map(country => ({
                  label: country.name,
                  value: country.code,
                  dialCode: country.dialCode
                }))}
                placeholder="Select Country"
                onChange={handleCountryCodeChange}
                isClearable
                styles={customStylesCountryCode}
              />
            </div>
            <div className="phone-wrapper">
              <div className="select-wrapper">
                <Select
                  options={countries.map(country => ({
                    label: country.dialCode,
                    value: country.dialCode
                  }))}
                  value={{ label: selectedCountryCode, value: selectedCountryCode }}
                  onChange={option => setSelectedCountryCode(option ? option.value : '+90')}
                  styles={customStylesCountryCode}
                  placeholder="Dial Code"
                />
              </div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder={``}
                required
              />
            </div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password2"
              placeholder="Confirm Password"
              value={formData.password2}
              onChange={handleChange}
              required
              className="input-no-border-bottom"
            />
          </form>
        </div>
        <button className="signup-submit-button" type="button" onClick={handleSubmitButtonClick}>Submit</button>
        <div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 
      </div>
      <ToastContainer />
    
    </div>
    </>
  );
};

export default Signup;
