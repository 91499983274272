import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import backgroundImageDesktop from '../../Assets/saarconcept-logo.webp'; // WebP image file
import WhatsappContact from '../../components/contact';
import Navbar from '../../components/navbar';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(true); // Add state for controlling visibility of WaitlistButton
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const formRef = useRef(null); // Create a ref for the form

  // Error messages mapping based on backend error codes
  const errorMessages = {
    INVALID_CREDENTIALS: 'Invalid email or password. Please try again.',
    USER_NOT_FOUND: 'No account found with this email. Please sign up.',
    EMAIL_ALREADY_EXISTS: 'This email is already in use. Please log in instead.',
    WEAK_PASSWORD: 'Your password is too weak. Please choose a stronger password.',
    SERVER_ERROR: 'There was an issue with the server. Please try again later.',
    UNKNOWN_ERROR: 'An unknown error occurred. Please try again.'
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted'); // Log to check if the form is being submitted
    const url = isSignup ? 'https://saarconcept.net/api/signup/' : 'https://saarconcept.net/api/login/';
    const method = 'POST';
  
    const payload = {
      email,
      password
    };
  
    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
      console.log('API Response:', data); // Log the API response
  
      if (response.ok) {
        if (data.access && data.refresh) { // Ensure both tokens are present
          // Log the tokens to the console
          console.log('Access Token:', data.access);
          console.log('Refresh Token:', data.refresh);
  
          // Store tokens in localStorage
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
  
          setIsLoggedIn(true); // Update login status here
          toast.success(`${isSignup ? 'Signup' : 'Login'} successful!`);
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else {
          toast.error('Unexpected error: Tokens missing.');
        }
      } else {
        if (data.non_field_errors) {
          toast.error(data.non_field_errors[0]);
        } else if (data.email) {
          toast.error(data.email[0]);
        } else {
          toast.error('An unknown error occurred.');
        }
      }
    } catch (error) {
      console.error("Network error or unexpected error:", error);
      toast.error(`Network error: ${error.message}`);
    }
  };
  
  
  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit(); // Programmatically submit the form
    }
  };

  const handleJoinClick = () => {
    setShowWaitlist(false); // Hide the WaitlistButton when joined
    console.log('User joined the waitlist');
  };
  
  const handleWaitClick = () => {
    console.log('Show waitlist form');
  };

  return (
    <div className="login-container">
       <Navbar />
       <img src={backgroundImageDesktop} alt="Background" className="background-image-desktop-login" />

      <div className="login-content">
        <div className="login-form">
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              className="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none'
              }}
            />
          </form>
        </div>
    
        <button
          className="login-button"
          onClick={handleButtonClick} // Log button click and submit form
        >
          {isSignup ? 'Sign Up' : 'Log In'}
        </button>
    
        <p className="waitlist-text-login">
          Join the waitlist to get <br />a chance to pre-order
        </p>
        <h3 className="login-heading">
          <Link to="/signup" className="login-link" onClick={() => setIsSignup(!isSignup)}>
            {'Sign Up'}
          </Link>
        </h3>

        <div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 
      </div>

      <ToastContainer /> {/* Add this to display toasts */}
    </div>
  );
};

export default Login;
