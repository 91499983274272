import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assets/logobrown.png';
import { LuShoppingCart, LuHeart, LuSearch } from 'react-icons/lu';
import { LuLogOut } from 'react-icons/lu'; // Import Logout icon
import { IoMenu, IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Added toast import
import 'react-toastify/dist/ReactToastify.css'; // Added toast CSS
import '../index.css';

const Navbar3 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [searchQuery, setSearchQuery] = useState('');
const [searchResults, setSearchResults] = useState([]);
const [error, setError] = useState(null);
const [searchPerformed, setSearchPerformed] = useState(false);
let debounceTimer;

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token'); // Clear access token
    setIsLoggedIn(false); // Update login state
    navigate('/'); // Redirect to the home page
  };
 



  // Toggle menu function for mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  // Handle scroll event to change navbar background and add shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Set to true if scrolled down
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Here you can still check for the access token if you'd like, but it won't affect the access flow
    const accessToken = localStorage.getItem('access_token'); // Check for access token

    if (accessToken) {
      setIsLoggedIn(true); // User is logged in (you can keep this for tracking if needed)
      console.log('User is logged in.');
    } else {
      setIsLoggedIn(false); // User is not logged in (again, can be kept for informational purposes)
      console.log('User is not logged in.');
      
    
    }
  }, []);

  const handleNavigation = (path) => {
    navigate(path); // Avoid unnecessary reload
  };

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [loading, setLoading] = useState(false); // Add a loading state

  const handleSearch = async () => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setError(null); // Ensure no error is set if the search is empty
      setLoading(false); // Stop loading when query is cleared
      return;
    }
  
    if (searchQuery.length < 3) {
      return; // Avoid unnecessary API calls
    }
  
    try {
      setLoading(true); // Start loading
      setSearchPerformed(true);
  
      const response = await fetch(
        `https://saarconcept.net/api/search/?format=json&q=${encodeURIComponent(searchQuery)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch search results");
      }
  
      const data = await response.json();
      if (data && data.results && Array.isArray(data.results)) {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredResults = data.results.filter(
          (result) =>
            result.name.toLowerCase().includes(lowerCaseQuery) ||
            result.description.toLowerCase().includes(lowerCaseQuery) ||
            result.product_colors.some((color) =>
              color.color.name.toLowerCase().includes(lowerCaseQuery)
            )
        );
  
        setSearchResults(filteredResults);
  
        if (filteredResults.length === 0) {
          setError("No results found."); // Only set error if no results are found
        } else {
          setError(null); // Clear error if results are found
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("Error fetching results. Please try again.");
      setSearchResults([]);
    } finally {
      setLoading(false); // Stop loading once search is complete
    }
  };
  
  
  useEffect(() => {
    // Debounce the API call
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSearch();
    }, 300); // Adjust debounce delay as needed (300ms is common)
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);
  
  
  const scrollToOutfit = () => {
    const outfitSection = document.getElementById('full-body-outfit');
    outfitSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav className={`p-2 w-full navbar-gradient2 ${isScrolled ? 'bg-[#3D291D] shadow-lg' : 'bg-transparent'} transition-shadow duration-300 ease-in-out`}>
      <div className="w-full flex items-center justify-between font-gotham px-4 lg:px-12">
        <div className="mobile-menu">
          {!isLoggedIn && (
            <div className="auth-links">
              <Link to="/signup" className="signup-link">Sign Up</Link>
              <Link to="/login" className="login-link3">Log In</Link>
            </div>
          )}
        </div>

        <div className="flex items-center">
          <button className="lg:hidden text-[#3D291D] mr-4 relative" onClick={toggleMenu} aria-label="Toggle menu">
            <IoMenu className="w-5 h-5" />
          </button>
          <Link to="/" onClick={() => handleNavigation('/')} className="ml-4 lg:ml-12">
            <img src={logo} alt="Logo" className="h-7 w-auto lg:h-12" />
          </Link>

          {/* Desktop Navigation links */}
          <ul className="hidden lg:flex space-x-6 ml-12 items-center font-gothamLight mt-3">
            <li>
              <Link to="/" onClick={() => handleNavigation('/')} className="text-[#3D291D]  no-underline text-sm">About Us</Link>
            </li>
            <span className="text-[#3D291D]">|</span>
            <li>
              <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-[#3D291D] no-underline text-sm">Shop</Link>
            </li>
            <span className="text-[#3D291D]">|</span>
            <li>
              <button onClick={scrollToFooter} className="text-[#3D291D] no-underline text-sm">Contact Us</button>
            </li>
          </ul>

        </div>

        {/* Icons */}
        <div className="flex items-center space-x-3 lg:mr-12">
       
          {isLoggedIn && (
            <>
              <Link to="/favorites">
      <LuHeart className="text-[#3D291D] w-5 h-5 cursor-pointer" aria-label="Wishlist" />
    </Link>
              <Link to="/cart">
  <LuShoppingCart 
    className="text-[#3D291D] w-5 h-5 cursor-pointer" 
    aria-label="Shopping cart" 
  />
</Link>
<LuLogOut
                className="text-[#3D291D] w-5 h-5 cursor-pointer"
                aria-label="Logout"
                onClick={handleLogout}
              />

            </>
          )}
   <div className="relative hidden lg:flex items-center mr-2">
  <input
    type="text"
    placeholder="Search..."
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    className="w-44 bg-transparent border-1 border-[#3D291D] rounded-full py-2 pl-10 pr-12 text-[#3D291D] placeholder-[#3D291D] focus:outline-none focus:ring-2 focus:ring-[#3D291D] text-sm"
  />
  <LuSearch
    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#3D291D] cursor-pointer"
    onClick={handleSearch}
  />
  {searchPerformed && (
    <div className="absolute top-full left-0 w-44 bg-[#3D291D] font-gothamLight text-[#FDF5F2] rounded-lg shadow-lg max-h-64 overflow-auto z-50 mt-2">
      {loading ? (
        <div className="text-center text-[#FDF5F2] py-2">Loading...</div>  
      ) : searchResults.length > 0 ? (
        <ul className="px-2">
          {searchResults.map((result, index) =>
            result.product_colors.map((productColor, colorIndex) => (
              <li
                key={`${index}-${colorIndex}`}
                className="px-2 py-2 border-b border-gray-300 cursor-pointer"
                onClick={() => {
                  const colorName = encodeURIComponent(productColor.color.name);
                  const navigationUrl = `/productDetail/${result.slug}?color=${colorName}`;
                  navigate(navigationUrl);
                  setSearchResults([]); // Clear dropdown after navigation
                }}
              >
                <div className="flex justify-between items-center">
                  <span className="text-sm">
                    {productColor.color.name} {result.name}
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      ) : (
        <div className="text-center">
          {error && (
            <div className="text-center text-red-500">{error}</div>
          )}
        </div>
      )}
    </div>
  )}
</div>

          {!isLoggedIn && (
            <>
              <Link to="/signup" onClick={() => handleNavigation('/signup')} className="signup-link-desktop">Sign Up</Link>
              <Link to="/login" onClick={() => handleNavigation('/login')} className="login-link-desktop3">Log In</Link>
            </>
          )}
        </div>
        {/* Desktop Search Bar */}

      </div>
{/* Mobile Search Bar under the Logo */}
<div className="lg:hidden flex flex-col items-center mt-2 relative w-full px-4">
  <div className="relative w-full">
    <input
      type="text"
      placeholder="Search..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full bg-transparent border-1 border-[#3D291D] rounded-full py-2 pl-10 pr-12 text-[#3D291D] placeholder-[#3D291D] focus:outline-none focus:ring-2 focus:ring-[#3D291D] text-sm"
    />
    <LuSearch
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#3D291D]"
      onClick={handleSearch}
    />
  </div>
  {searchQuery && searchPerformed && ( 
    <div className="absolute top-full left-0 right-0 bg-[#3D291D] font-gothamLight text-[#FDF5F2] rounded-lg shadow-lg w-full max-h-64 overflow-auto z-50 mt-2 px-4 py-2">
      {loading ? (
        <div className="text-center text-[#FDF5F2] py-2">Loading...</div>
      ) : searchResults.length > 0 ? (
        <ul className="max-h-64 overflow-auto">
          {searchResults.map((result, index) =>
            result.product_colors.map((productColor, colorIndex) => (
              <li
                key={`${index}-${colorIndex}`}
                className="px-4 py-2 border-b border-gray-300 cursor-pointer"
                onClick={() => {
                  const colorName = encodeURIComponent(productColor.color.name);
                  const navigationUrl = `/productDetail/${result.slug}?color=${colorName}`;
                  navigate(navigationUrl);
                  setSearchResults([]); // Clear dropdown after navigation
                }}
              >
                <div className="flex justify-between items-center">
                  <span className="font-sm">
                    {productColor.color.name} {result.name}
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      ) : (
        <div className="text-center">
          {error && (
            <div className="text-center text-red-500">{error}</div>
          )}
        </div>
      )}
    </div>
  )}
</div>


      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-12 left-4 right-4 z-40 bg-[#3D291D] rounded-lg p-4 w-40 shadow-lg">
          <button className="absolute top-2 right-2 text-[#FDF5F2]" onClick={toggleMenu} aria-label="Close menu">
            <IoClose className="w-5 h-5" />
          </button>
          {/* Ensure items are aligned in a column */}
          <div className="flex flex-col items-start space-y-4 text-left">
            <Link to="/" onClick={() => handleNavigation('/')} className="text-[#FDF5F2] text-sm no-underline">About Us</Link>
            <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-[#FDF5F2] text-sm no-underline">Categories</Link>
            <button onClick={scrollToFooter} className="text-[#FDF5F2] text-sm no-underline">Contact Us</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar3;
