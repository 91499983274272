import React, { useEffect, useState } from "react";
import { GoTrash } from "react-icons/go";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Navbar3 from '../components/navbarbrown';

const AddCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const navigate = useNavigate();

  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    JPY: '¥',
    AUD: 'A$',
    CAD: 'C$',
    TRY: '₺',
    IQD: 'IQD',
  };

  const formatPrice = (price, currency) => {
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      console.error(`Invalid price: ${price}`);
      return 'N/A';
    }
    const symbol = currencySymbols[currency] || currency;
    return `${symbol}${numericPrice.toFixed(2)}`;
  };

  const getUserLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setUserLocation(data.country_code);
    } catch (error) {
      console.error('Error fetching location:', error);
      setUserLocation('GLOBAL');
    }
  };

 

  const calculateTotalQuantity = (item) =>
    item.selectedSizes.reduce((total, size) => total + size.quantity, 0);

  const calculateTotalCartQuantity = () =>
    cartItems.reduce((total, item) => total + calculateTotalQuantity(item), 0);

  const getItemCounts = () =>
    cartItems.reduce((acc, curr) => {
      acc[curr.name] = (acc[curr.name] || 0) + 1;
      return acc;
    }, {});

  const getOccurrencesSoFar = (item, index) =>
    cartItems.slice(0, index + 1)
      .filter(cartItem => cartItem.name === item.name).length;

      const calculateCartTotal = () => {
        let total = 0;
        cartItems.forEach((item, index) => {
          const basePrice = Number(item.price) || Number(item.base_price);
          const itemCounts = getItemCounts();
          const occurrencesSoFar = getOccurrencesSoFar(item, index);
          const apply40Discount = occurrencesSoFar === 2 && itemCounts[item.name] >= 2;
          const itemDiscount = apply40Discount ? 0.6 : 0.8;
          const discountedPrice = basePrice * itemDiscount;
      
          // Apply promo code discount
          const promoCodeDiscount = isValid ? 1 - (item.discount || 0) : 1;
      
          // Calculate final price with promo code
          const finalPrice = discountedPrice * promoCodeDiscount;
      
          total += finalPrice * item.selectedSizes.reduce(
            (sizeTotal, { quantity }) => sizeTotal + quantity,
            0
          );
        });
        return total;
      };
      

      const handleProductClick = (slug, colorName) => {
        if (slug) {
          // Navigate to the product detail page
          navigate(`/productDetail/${slug}?color=${colorName}`);
      
          // Scroll to the top of the page
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };
      

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };
  const [promoCodeDiscountPercentage, setPromoCodeDiscountPercentage] = useState(0);

  const validatePromoCode = async () => {
    if (!promoCode) return;
  
    const encodedPromoCode = encodeURIComponent(promoCode);
  
    try {
      const response = await fetch(`https://saarconcept.net/api/check-promo-code/?code=${encodedPromoCode}`);
  
      if (!response.ok) {
        throw new Error('Promo code validation failed');
      }
  
      const data = await response.json();
  
      if (data.exists && data.is_valid) {
        const discountValue = data.details.discount_value; // For example, 10 for 10% off
        setPromoCodeDiscountPercentage(discountValue); // Set the discount as the value (10 for 10%)
  
        const updatedItems = cartItems.map((item) => ({
          ...item,
          discount: discountValue / 100, // Apply promo code discount to each item
        }));
  
        setCartItems(updatedItems);
        setIsValid(true);
      } else {
        alert('Promo code is invalid or expired.');
        setIsValid(false);
      }
    } catch (error) {
      alert('An error occurred while validating the promo code.');
      setIsValid(false);
    }
  };
  
  
  useEffect(() => {
    getUserLocation();
    try {
      const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedItems = storedCartItems.map((item) => {
        // Ensure all required properties exist with default values
        const sanitizedItem = {
          name: item.name || 'Unknown Product',
          color: item.color || 'Default',
          price: item.price || item.base_price || 0,
          currency: item.currency || 'USD',
          images: Array.isArray(item.images) ? item.images : [],
          image: item.image || '', // Fallback image
          slug: item.slug || '',
          size: item.size || null
        };

        // Handle sizes with validation
        const defaultSizes = [
          { size: "S", quantity: 0 },
          { size: "M", quantity: 0 },
          { size: "L", quantity: 0 },
        ];

        const sizeStored = sanitizedItem.size;
        const selectedSizes = defaultSizes.map((sizeObj) => ({
          ...sizeObj,
          quantity: sizeStored === sizeObj.size ? 1 : 0,
        }));

        return {
          ...sanitizedItem,
          selectedSizes,
        };
      });

      setCartItems(updatedItems);
    } catch (error) {
      console.error("Error parsing cart items from localStorage:", error);
      setCartItems([]);
    }
  }, []);


  const updateMainQuantity = () => {
    const updatedItems = cartItems.map((item) => ({
      ...item,
      quantity: calculateTotalQuantity(item),
    }));
    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };

  const increaseQuantity = (index, size) => {
    const updatedItems = [...cartItems];
    const item = updatedItems[index];
    const sizeIndex = item.selectedSizes.findIndex((s) => s.size === size);

    if (sizeIndex !== -1) {
      item.selectedSizes[sizeIndex].quantity += 1;
    }

    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
    updateMainQuantity();
  };

  const decreaseQuantity = (index, size) => {
    const updatedItems = [...cartItems];
    const item = updatedItems[index];
    const sizeIndex = item.selectedSizes.findIndex((s) => s.size === size);

    if (sizeIndex !== -1 && item.selectedSizes[sizeIndex].quantity > 0) {
      item.selectedSizes[sizeIndex].quantity -= 1;
    }

    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
    updateMainQuantity();
  };

  const removeItem = (index) => {
    const updatedItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };

  const handleCheckout = () => {
    if (cartItems.length === 0) {
      alert("Your cart is empty!");
      return;
    }
    setShowOptions(true);
  };
  const handleOptionSelect = (choice) => {
    const total = calculateCartTotal().toFixed(2);
    const whatsappNumber = choice === "iraq" ? "+9647774089987" : "+905362887229";
  
    // Determine currency based on user location
    let currency = 'USD'; // Default to USD
    let currencySymbol = '$'; // Default symbol for USD
  
    if (userLocation === 'IQ') {
      currency = 'IQD';
      currencySymbol = 'IQD';
    } else if (userLocation === 'TR') {
      currency = 'TRY';
      currencySymbol = '₺';
    } else if (userLocation === 'US') {
      currency = 'USD';
      currencySymbol = '$';
    } else if (userLocation === 'GB') {
      currency = 'GBP';
      currencySymbol = '£';
    } else if (userLocation === 'EU') {
      currency = 'EUR';
      currencySymbol = '€';
    }
  
    const message = cartItems
      .map((item) => {
        const basePrice = Number(item.price) || Number(item.base_price);
        const itemCounts = getItemCounts();
        const occurrencesSoFar = getOccurrencesSoFar(item);
        const discount = occurrencesSoFar === 2 && itemCounts[item.name] >= 2 ? 0.6 : 0.8;
        const finalPrice = basePrice * discount;
  
        return item.selectedSizes
          .filter((sizeData) => sizeData.quantity > 0)
          .map((sizeData) => {
            return `- ${item.name} (${item.color}, Size: ${sizeData.size}) x${sizeData.quantity} = ${currencySymbol}${(finalPrice * sizeData.quantity).toFixed(2)}`;
          })
          .join("%0A");
      })
      .filter(Boolean)
      .join("%0A");
  
    if (!message) {
      alert("No items in the cart to checkout!");
      return;
    }
  
    // Build the message with promo code only if it's provided
    let fullMessage = `Hello, I would like to order the following items:%0A${message}%0A%0ATotal: ${currencySymbol}${total}`;
  
    if (promoCode) {
      fullMessage += `%0A%0APromo Code: ${promoCode} (Discount: ${promoCodeDiscountPercentage}%)`;
    }
  
    window.open(`https://wa.me/${whatsappNumber}?text=${fullMessage}`, "_blank");
    setShowOptions(false);
  };
  
  
  
  

  return (
    <div className="flex flex-col items-center justify-center bg-[#FDF5F2] px-3 sm:px-8 pb-2">
      <Navbar3/>
      <div className="flex flex-col sm:items-center items-start ml-16 mb-0 mt-0 sm:justify-center w-full">
        <h1 className="text-2xl sm:text-4xl font-bold font-gothamBold text-[#3D291D] mt-8 lg:mt-16 mb-0">
          Cart
        </h1>
        <IoIosArrowDown className="text-[#3D291D] text-2xl sm:text-4xl mt-2 hidden sm:block" />
      </div>

      {cartItems.map((item, index) => {
        const basePrice = Number(item.price) || Number(item.base_price);
        const itemCounts = getItemCounts();
        const occurrencesSoFar = getOccurrencesSoFar(item, index);
        const apply40Discount = occurrencesSoFar === 2 && itemCounts[item.name] >= 2;
        const finalPrice = apply40Discount ? basePrice * 0.6 : basePrice * 0.8;
        return (
          <div key={index} className="w-full max-w-[50rem] h-[12rem] sm:h-[15rem] bg-[#FDF5F2] flex items-center px-0 sm:px-6 relative border-b-2 border-[#3D291D]">
            <div className="w-28 h-28 sm:w-44 sm:h-44 mr-4 sm:mr-12">
              <img
                onClick={() => handleProductClick(item.slug, item.color)}
                src={item.images?.[0]?.image || item.image || "default-image.jpg"}
                alt={item.images?.[0]?.alt_text || item.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-center mb-0">
                <h3
                  onClick={() => handleProductClick(item.slug, item.color)}
                 className="text-sm sm:text-3xl font-gothamBold font-bold text-[#3D291D]">
                  {item.color} {item.name}
                </h3>
                <button onClick={() => removeItem(index)} className="text-[#3D291D] mr-8 hover:text-[#FF5733]">
                  <GoTrash size={20} />
                </button>
              </div>
              <div className="flex items-center mt-2 space-x-3">
  <span className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] line-through">
    {formatPrice(basePrice, item.currency)}
  </span>
  <span className="text-lg sm:text-2xl font-gothamBlack text-[#7DDED9]">
    {formatPrice(finalPrice, item.currency)} {/* Final price after all discounts */}
  </span>
</div>

              
              <div className="flex flex-col mt-2">
                {["S", "M", "L"].map((size) => (
                  <div key={size} className="flex items-center justify-start mb-0 sm:mb-4">
                    <span className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] w-6">
                      {size}
                    </span>
                    <div className="flex items-center space-x-2 ml-2">
                      <button
                        onClick={() => decreaseQuantity(index, size)}
                        className={`w-6 h-4 sm:w-8 sm:h-4 flex items-center justify-center rounded-full border-1 ${
                          item.selectedSizes?.find((s) => s.size === size)?.quantity > 0
                            ? "border-[#3D291D] hover:bg-[#FA8F21] active:bg-[#FA8F21]"
                            : "border-gray-300 cursor-not-allowed"
                        } transition`}
                        disabled={!item.selectedSizes?.find((s) => s.size === size)?.quantity}
                      >
                        <HiOutlineMinus size={14} className="sm:text-[20px]" color="#3D291D" />
                      </button>
                      <div className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] text-center w-6">
                        {item.selectedSizes?.find((s) => s.size === size)?.quantity || 0}
                      </div>
                      <button
                        onClick={() => increaseQuantity(index, size)}
                        className="w-6 h-4 sm:w-8 sm:h-4 flex items-center justify-center rounded-full border-1 border-[#3D291D] hover:bg-[#FA8F21] active:bg-[#FA8F21] transition"
                      >
                        <HiOutlinePlus size={14} className="sm:text-[20px]" color="#3D291D" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}

<div className="flex flex-col w-full max-w-[45rem] mt-4 pr-4 sm:pr-6">
  <div className="flex flex-col mb-4 ml-auto">
    <span className="text-sm sm:text-lg font-gothamBold text-[#3D291D]">
      Total: 
      <span className="text-[#3D291D] text-sm line-through mr-2">
        {formatPrice(
          cartItems.reduce((total, item) => {
            const basePrice = Number(item.price) || Number(item.base_price);
            return total + (basePrice * item.selectedSizes.reduce(
              (sizeTotal, { quantity }) => sizeTotal + quantity,
              0
            ));
          }, 0),
          cartItems[0]?.currency || 'USD'
        )}
      </span>
      <span className="font-bold text-[#3D291D]">
        {formatPrice(calculateCartTotal(), cartItems[0]?.currency || 'USD')}
      </span>
    </span>
  </div>


        <div className="flex ml-auto">
          <input
            type="text"
            value={promoCode}
            onChange={handlePromoCodeChange}
            placeholder="Promo Code"
            className="w-32 sm:w-45 h-10 sm:h-12 bg-transparent text-sm font-gothamBold text-[#3D291D] mr-2 placeholder-[#3D291D] focus:outline-none"
            style={{
              borderTop: '0',
              borderLeft: '0',
              borderRight: '0',
              borderBottom: '2px solid #3D291D',
              borderRadius: '0',
            }}
          />
          <span
            className="text-[#3D291D]"
            onClick={validatePromoCode}
          >
            <FaArrowRight size={10} className="-ml-1 mt-3 sm:mt-4" style={{ cursor: 'pointer' }} />
          </span>
          <div className="relative inline-block">
            <button
              onClick={handleCheckout}
              disabled={calculateTotalCartQuantity() === 0}
              className={`w-32 sm:w-36 md:w-40 h-10 sm:h-12 md:h-10 ml-4 sm:ml-8 rounded-3xl text-white text-sm sm:text-lg font-gothamBold transition duration-300 ${
                calculateTotalCartQuantity() === 0
                  ? 'bg-[#FA8F21] cursor-not-allowed'
                  : 'bg-[#FA8F21] hover:shadow-md'
              }`}
            >
              Check out ({calculateTotalCartQuantity()})
            </button>
            {showOptions && (
              <div
                className="absolute left-0 mt-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 p-2 rounded-2xl shadow-md w-40 md:w-56"
                style={{
                  backgroundColor: '#FDF5F2',
                  color: '#FA8F21',
                  fontFamily: 'GothamBold',
                  fontWeight: 'bold',
                  zIndex: 50,
                }}
              >
                <button
                  className="px-2 md:px-3 py-1 rounded-md"
                  onClick={() => handleOptionSelect("worldwide")}
                  style={{ color: '#FA8F21' }}
                >
                  World Wide
                </button>
                <span
                  className="hidden md:inline mt-1"
                  style={{ color: '#FA8F21', fontWeight: 'bold' }}
                >
                  or
                </span>
                <button
                  className="px-2 md:px-3 py-1 rounded-md"
                  onClick={() => handleOptionSelect("iraq")}
                  style={{ color: '#FA8F21' }}
                >
                  Iraq
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCart;