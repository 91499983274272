import React from 'react';
import Hero from '../../components/hero';  // Adjust the path to where your Hero component is located
import heroVideo from '../../Assets/heroVideo.mp4';
import aboutUsImage from '../../Assets/aboutus.webp';  // Adjust the path to your image file
import iconPath1 from '../../Assets/tabler-icon-puzzle-2.webp';  // Adjust the path to your first icon file
import iconPath2 from '../../Assets/Eco_Fashion.webp';  // Adjust the path to your second icon file
import iconPath3 from '../../Assets/tabler-icon-sparkles.webp';  // Adjust the path to your third icon file
import FullBodyOutfit from '../../components/fulboduoutfit';  // Adjust the path to your FullBodyOutfit component
import Collection from '../../components/collection';


const Aboutus = () => {
  return (
    <div className="relative bg-[#FDF5F2]">
      {/* Hero section */}
      <Hero />

      {/* About us content */}
      <div className="flex flex-col items-center justify-start mt-16 pt-0 px-4"> {/* Adjusted pt-16 to pt-0 for less top padding */}
        <h2 className="text-4xl sm:text-6xl font-gothamBold text-[#FA8F21] tracking-[0.9em]">
          SAAR
        </h2>
        {/* Additional text */}
        <p className="text-sm sm:text-xl font-gothamLight text-[#3D291D] mt-2 text-center max-w-3xl"> {/* Adjusted font size for mobile */}
          Style&nbsp;&nbsp;|&nbsp;&nbsp;Artistry&nbsp;&nbsp;|&nbsp;&nbsp;Authenticity&nbsp;&nbsp;|&nbsp;&nbsp;Resilience
        </p>
        <p className="text-sm sm:text-xl font-gothamLight text-[#3D291D] mt-2 text-center max-w-3xl"> {/* Adjusted font size for mobile */}
          Each letter stands for something we hold dear, and together, they form the foundation of everything we create. It isn’t just a brand; it’s a reflection of who we are and what we believe in.
        </p>
      </div>

      {/* Video section */}
      <div className="w-full mt-[6rem] relative mt-12 h-64 sm:h-auto"> {/* Adjusted height for mobile */}
        <video
          src={heroVideo} // Replace with your video file path or URL
          autoPlay
          loop
          muted
          className="w-full h-full object-cover" // Set to full width and height
        />
      </div>

    {/* About Us heading and image */}
<div  id="about-us" className="flex flex-col items-center justify-start mt-8 pt-16 px-2">
  <hr className="w-full border-t-2 border-[#3D291D] mb-8" />
  <h2 className="text-3xl sm:text-4xl sm:mb-4 font-gothamBold text-[#3D291D]">
  About Us
</h2>

  {/* Image */}
  <img
  src={aboutUsImage} // Replace with your image file path
  alt="About Us"
  className="mt-2 px-2 w-full sm:w-1/2 h-auto object-cover" // Changed w-3/4 to w-full for mobile
/>

<div className="flex justify-center items-start mt-8 px-0 space-x-4 sm:space-x-12">
  {/* Left column */}
  <div className="text-center text-sm sm:text-xl font-gothamBold text-[#3D291D]">
    {/* Icon */}
    <img src={iconPath1} alt="Icon 1" className="w-6 h-6 sm:w-8 sm:h-8 mx-auto mb-2" />
    <p className="mb-0">Versatile &</p>
    <p className="mb-0">Easy to Use</p>
  </div>

  {/* Separator */}
  <div className="border-r-2 border-[rgba(61,41,29,0.34)] h-20 sm:h-28"></div>

  {/* Center column */}
  <div className="text-center text-sm sm:text-xl font-gothamBold text-[#3D291D]">
    {/* Icon */}
    <img src={iconPath2} alt="Icon 2" className="w-6 h-6 sm:w-8 sm:h-8 mx-auto mb-2" />
    <p className="mb-0">Sustainable &</p>
    <p className="mb-0">Eco-friendly</p>
  </div>

  {/* Separator */}
  <div className="border-r-2 border-[rgba(61,41,29,0.34)] h-20 sm:h-28"></div>

  {/* Right column */}
  <div className="text-center text-sm sm:text-xl font-gothamBold text-[#3D291D]">
    {/* Icon */}
    <img src={iconPath3} alt="Icon 3" className="w-6 h-6 sm:w-8 sm:h-8 mx-auto mb-2" />
    <p className="mb-0">Modern &</p>
    <p className="mb-0">Reflective of You</p>
  </div>
</div>
</div>


  
      {/* FullBodyOutfit Component */}
      <div className="mt-12 bg-[#FDF5F2]">
        <FullBodyOutfit />
      </div>
      <div className="bg-[#FDF5F2]">
        <Collection />
      </div>
    
    </div>
  );
};

export default Aboutus;
