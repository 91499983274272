import React, { useEffect, useState } from 'react';
import { LuHeart } from 'react-icons/lu'; 
import { FaHeart } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io'; 
import axios from 'axios';
import icon from '../Assets/icon.png';
import { useNavigate } from 'react-router-dom';
import WhatsappContact from '../components/contact';
import LoginSignupModal from '../components/LoginSignupModal'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar2 from '../components/navbarblue';

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [colors, setColors] = useState([]); 
  const [selectedCategories, setSelectedCategories] = useState([]); 
  const [selectedColors, setSelectedColors] = useState([]); 
  const [isClicked, setIsClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  const navigate = useNavigate();

  const navigateToCart = () => {
    navigate('/cart');
  };

  const getUserLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setUserLocation(data.country_code);
    } catch (error) {
      console.error('Error fetching location:', error);
      setUserLocation('GLOBAL');
    }
  };

  const getProductPrice = (color) => {
    if (!color.country_prices || !userLocation) {
      return {
        amount: color.base_price,
        currency: 'USD'
      };
    }

    const locationPrice = color.country_prices.find(
      price => price.country === userLocation
    );

    if (locationPrice) {
      return {
        amount: locationPrice.price_amount,
        currency: locationPrice.price_currency
      };
    }

    return {
      amount: color.base_price,
      currency: 'USD'
    };
  };
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    JPY: '¥',
    AUD: 'A$',
    CAD: 'C$',
    TRY: '₺', // Turkish Lira symbol
    IQD: 'IQD',
    // Add more currency codes and symbols as needed
  };
  
  const formatPrice = (price, currency) => {
  
    const numericPrice = parseFloat(price); // Ensure price is a number
    if (isNaN(numericPrice)) {
      console.error(`Invalid price: ${price}`);
      return 'N/A'; // Fallback for invalid prices
    }
  
    const symbol = currencySymbols[currency] || currency; // Use currency code if symbol not found
    return `${symbol}${numericPrice.toFixed(2)}`;
  };
  
  const handleAddToCart = (product, color) => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const price = getProductPrice(color);
    const formattedPrice = formatPrice(price.amount, price.currency);
  
    const isProductInCart = cartItems.some(
      (item) => item.id === product.id && item.color === color.color.name
    );
  
    if (isProductInCart) {
      toast.warning('Product is already in the cart!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    const cartItem = {
      id: product.id,
      name: product.name,
      slug: product.slug,
      color: color.color.name,
      size: 'S',
      price: price.amount,
      currency: price.currency,
      currencySymbol: price.currencySymbol, // Assuming this is returned from `getProductPrice`
      formattedPrice: formattedPrice, // Add the formatted price to the cart item
      images: color.images || [],
    };
  
    cartItems.push(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItems));
  
    toast.success('Added to cart!', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  
    navigateToCart();
  };
  

  const handleCategoryClick = (categoryId) => {
    setSelectedCategories(prevSelectedCategories => 
      prevSelectedCategories.includes(categoryId) 
        ? prevSelectedCategories.filter(id => id !== categoryId)
        : [...prevSelectedCategories, categoryId]
    );
  };

  const handleColorClick = (color) => {
    setSelectedColors(prevSelectedColors => 
      prevSelectedColors.includes(color) 
        ? prevSelectedColors.filter(c => c !== color)
        : [...prevSelectedColors, color]
    );
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setIsClicked(false);
  };

  const handleClick = () => {
    setIsClicked(true);
  };

  const filteredProducts = products.filter((product) => {
    const categoryMatch = selectedCategories.length > 0 
      ? selectedCategories.includes(product.category.id)
      : true;

    const colorMatch = selectedColors.length > 0 
      ? product.product_colors.some(color => selectedColors.includes(color.color.name))
      : true;

    return categoryMatch && colorMatch;
  });

  const handleProductClick = (slug, colorName) => {
    setTimeout(() => {
      window.location.reload();
    }, 0);
    
    navigate(`/productDetail/${slug}?color=${colorName}`);
  };

  const fetchFavorites = async (token) => {
    try {
      const response = await fetch('https://saarconcept.net/api/favorites/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (response.ok) {
        const data = await response.json();
        // Transform the API response data structure correctly
        const formattedFavorites = data.map(fav => ({
          product_id: fav.product_color?.product?.id, // Access nested product id
          color_id: fav.product_color?.id
        })).filter(fav => fav.product_id && fav.color_id); // Filter out any invalid entries
        
        setFavorites(formattedFavorites);
        localStorage.setItem('favorites', JSON.stringify(formattedFavorites));
        
        console.log('Formatted favorites:', formattedFavorites); // For debugging
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };
   useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('access_token');
        
        // Make the API call without authentication headers first
        const response = await axios.get('https://saarconcept.net/api/products-with-filters/');
        
        const products = response.data.products;
        
        // If user is logged in, make a second call to get favorites status
        if (token) {
          try {
            const authenticatedResponse = await axios.get(
              'https://saarconcept.net/api/products-with-filters/',
              { headers: { Authorization: `Bearer ${token}` } }
            );
            
            // Merge the favorite status with the products
            const updatedProducts = products.map(product => {
              const authenticatedProduct = authenticatedResponse.data.products.find(
                p => p.id === product.id
              );
              return {
                ...product,
                product_colors: product.product_colors.map(color => {
                  const authenticatedColor = authenticatedProduct?.product_colors.find(
                    c => c.id === color.id
                  );
                  return {
                    ...color,
                    is_favorite: authenticatedColor?.is_favorite || false
                  };
                })
              };
            });
            
            setProducts(updatedProducts);
          } catch (error) {
            if (error.response?.status === 401) {
              const newToken = await refreshAccessToken();
              if (newToken) {
                fetchData();
              }
            } else {
              console.error('Error fetching authenticated data:', error);
              setProducts(products);
            }
          }
        } else {
          // If not logged in, just set the products without favorite status
          setProducts(products);
        }
        
        setCategories(response.data.categories);
        
        // Extract unique colors
        const uniqueColors = Array.from(
          new Set([
            ...products.flatMap(product =>
              product.product_colors.map(color => color.color.name)
            ),
            'Black',
            'White',
          ])
        );
        setColors(uniqueColors);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    getUserLocation();

    // Load favorites from localStorage
    const savedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(savedFavorites);
  }, []);
  
  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        console.warn('No refresh token found');
        navigate('/login');// Allow the user to access the page without being redirected
      }
  
      const response = await fetch('https://saarconcept.net/api/token/refresh/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error('Failed to refresh token');
      }
  
      const data = await response.json();
      localStorage.setItem('access_token', data.access); // Save the new access token
      setIsLoggedIn(true); // Update the login state
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error.message);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setIsLoggedIn(false); // Set login state to false
      return null; // Do not redirect, just return null
    }
  };
  
  const handleActionClick = async (actionType, product, color) => {
    let token = localStorage.getItem('access_token');
  
    if (!token) {
      setIsModalOpen(true);
      return;
    }
  
    const url = `https://saarconcept.net/api/favorites/?product_color_id=${color.id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    if (!color.id) {
      console.warn('Product color is missing ID.');
      return;
    }
  
    try {
      // Optimistic UI update: Update the heart icon state immediately
      if (actionType === 'addToWishlist') {
        color.is_favorite = true; // Set the heart to filled immediately
      } else {
        color.is_favorite = false; // Set the heart to empty immediately
      }
  
      // Update the state of favorites before making the API request
      let updatedFavorites = [...favorites];
  
      if (actionType === 'addToWishlist') {
        updatedFavorites = [...updatedFavorites, { ...product, color_id: color.id }];
      } else {
        updatedFavorites = updatedFavorites.filter(
          (fav) => !(fav.product_id === product.id && fav.color_id === color.id)
        );
      }
  
      setFavorites(updatedFavorites);
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  
      const makeApiRequest = async () => {
        const response = await fetch(url, {
          method: actionType === 'addToWishlist' ? 'POST' : 'DELETE',
          headers,
          body: actionType === 'addToWishlist' ? JSON.stringify({ product_color_id: color.id }) : null,
        });
        return response;
      };
  
      let response = await makeApiRequest();
  
      if (response.status === 401) {
        // If the token is expired, try refreshing it
        const refreshedToken = await refreshAccessToken();
        if (refreshedToken) {
          // Save the refreshed token to localStorage
          localStorage.setItem('access_token', refreshedToken);
  
          // Retry the original API request with the new token
          response = await makeApiRequest();
        } else {
          // Token refresh failed, prompt the user to log in again
          setIsModalOpen(true);
          return;
        }
      }
  
      if (response.ok) {
        if (actionType === 'addToWishlist') {
          // Navigate to the /favorites page after successfully adding the item
          window.location.href = '/favorites';
        }
      } else {
        const data = await response.json();
        console.error('API error response:', data);
        // If the API request fails, revert the optimistic UI changes
        setFavorites(favorites);
        localStorage.setItem('favorites', JSON.stringify(favorites));
      }
    } catch (error) {
      console.error('API error:', error);
      // If the API request fails, revert the optimistic UI changes
      setFavorites(favorites);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
  };
  
  
  
  return (
    <div className="bg-[#FDF5F2] min-h-screen">
      <Navbar2 />
      <ToastContainer />
      <div className="flex flex-col items-center">
        <h1 className="text-3xl font-bold font-gothamBold text-[#0F2B2A] mt-4 lg:hidden">
          Products
        </h1>
        <h1 className="text-4xl font-bold font-gothamBold text-[#0F2B2A] mt-16 hidden lg:block">
          Products
        </h1>
        <IoIosArrowDown className="text-[#0F2B2A] text-4xl mt-2 hidden sm:block" />
      </div>

      <div className="flex flex-col lg:flex-row lg:mx-32 py-8">
        <div className="w-full lg:w-1/6 lg:block lg:mr-4 mb-4 lg:mb-0">
          <div className="flex items-center mb-4">
            <div className="mr-2">
              <img src={icon} alt="Filter Icon" className="w-6 h-6 hidden lg:block" />
            </div>
            <h2 className="text-2xl font-bold font-gothamBold text-[#3D291D] hidden lg:block">Filters</h2>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-bold font-gothamBold text-[#3D291D] mb-2 hidden lg:block">Colors</h3>
            <ul className="flex flex-wrap">
              {colors.map((color) => {
                const colorHex = {
                  "Navy Blue": "#173560",
                  "Olive Green": "#7BA785",
                  "Off White": "#FFF7F5",
                  "Black": "#000000",
                  "White": "#FFFFFF"
                }[color];

                const borderStyle = selectedColors.includes(color)
                  ? "border-2 border-[#0F2B2A]"
                  : "border-1 border-[#0F2B2A]";

                return (
                  <li key={color} className="mr-1 mb-2">
                    <button
                      aria-label={color}
                      className={`rounded-full transition-opacity duration-200 ease-in-out ${borderStyle} ${selectedColors.includes(color) ? 'opacity-75' : ''}`}
                      onClick={() => handleColorClick(color)}
                      style={{
                        backgroundColor: colorHex,
                        width: '40px',
                        height: '40px',
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-bold font-gothamBold text-[#3D291D] mb-2 hidden lg:block">Categories</h3>
            <ul className="flex flex-wrap">
              {categories.map((category) => (
                <li key={category.id} className="mr-2 mb-2">
                  <button 
                    className={`text-sm font-gothamLight text-[#3D291D] border-1 border-[#3D291D] rounded-3xl py-1 px-2 
                      ${selectedCategories.includes(category.id) ? 'bg-[#FA8F21] text-white' : 'bg-transparent'}
                      hover:bg-[#FA8F21] transition-colors duration-200 ease-in-out cursor-pointer`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div
            onClick={() => {
              handleClick(); 
              clearFilters();
            }} 
            className="text-[#3D291D] font-gothamBold text-sm md:text-md hover:bg-[#3D291D] hover:text-white border-3 border-[#3D291D] ml-6 rounded-full px-3 py-1 md:px-2 md:py-2 transition duration-300 no-underline decoration-none hover:bg-[#3D291D] hover:text-white flex justify-center items-center w-auto max-w-[200px]"
          >
            <span>Clear Filters</span>
          </div>
        </div>

        <div className="w-full lg:w-5/6 lg:ml-8">
          {loading ? (
            <div className="flex justify-center items-center min-h-[300px]">
              <Spinner />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-0 sm:gap-3 lg:gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {filteredProducts.map((product) =>
              product.product_colors
                .filter(color => 
                  selectedColors.length === 0 || 
                  selectedColors.includes(color.color.name)
                )
                .map((color) =>
                  color.images.map((image) => {
                    const price = getProductPrice(color);
                    const isFavorite = favorites.some(
                      (fav) => 
                        fav.product_id === product.id && 
                        fav.color_id === color.id
                    );
                     return (
                        <div key={image.id} className="flex flex-col items-center justify-between p-2 mb-4">
                          <div className="w-full relative" style={{ paddingBottom: '100%' }}>
                            <img
                              src={image.image}
                              alt={image.alt_text}
                              className="absolute top-0 left-0 w-full h-full object-cover object-center"
                              onClick={() => handleProductClick(product.slug, color.color.name)}
                            />
                          </div>

                          <div className="w-full p-2">
                            <h2 className="text-xl font-bold font-gothamBold text-[#3D291D]">
                              {color.color.name} {product.name}
                            </h2>
                            <p className="text-lg font-light font-montserrat text-[#3D291D]">
                            <span className="line-through text-sm">
  {formatPrice(price.amount, price.currency)}
</span>
<span className="ml-2 text-[#7DDED9] font-gothamBlack">
  {formatPrice(price.amount * 0.8, price.currency)}
</span>
                            </p>
                            </div>

                          <div className="flex justify-between items-center w-full p-2">
                            <button
                              onClick={() => handleAddToCart(product, color)}
                              className="bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-3 sm:px-3 text-sm sm:text-base rounded-3xl"
                            >
                              Add to Cart
                            </button>
                            {color.is_favorite ? (
  <FaHeart
    className="text-[#FA8F21] text-2xl cursor-pointer"
    onClick={() => handleActionClick('removeFromWishlist', product, color)}
  />
) : (
  <LuHeart
    className="text-[#FA8F21] text-2xl cursor-pointer"
    onClick={() => handleActionClick('addToWishlist', product, color)}
  />
)}


 
                          </div>
                        </div>
                      );
                    })
                  )
              )}
            </div>
          )}
        </div>
      </div>
      <LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className="fixed bottom-32 right-4 lg:right-8 z-50">
        <WhatsappContact />
      </div>
    </div>
  );
};

export default Products;