import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { LuHeart } from 'react-icons/lu';
import LoginSignupModal from './LoginSignupModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const FullBodyOutfit = () => {
  const [outfits, setOutfits] = useState([]);
  const [centerOutfit, setCenterOutfit] = useState(0);
  const [movementDistance, setMovementDistance] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    getUserLocation();
    
    const fetchOutfits = async () => {
      let token = localStorage.getItem('access_token');
      
      if (!token) {
        toast.error('Please log in to fetch outfit details.');
        return;
      }
  
      const makeApiRequest = async () => {
        const response = await fetch('https://saarconcept.net/api/products-with-filters/', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Include the token for authentication
          }
        });
        return response;
      };
  
      try {
        let response = await makeApiRequest();
  
        // If the token is expired, try refreshing it
        if (response.status === 401) {
          token = await refreshAccessToken(); // Refresh the token
          if (token) {
            response = await makeApiRequest(); // Retry the request with the new token
          } else {
            toast.error('Session expired. Please log in again.');
            return; // Token refresh failed; user needs to reauthenticate
          }
        }
  
        const data = await response.json();
        console.log('API Response:', data);
  
        // Check if the response contains the products array
        if (!data.products || !Array.isArray(data.products)) {
          throw new Error('Invalid data format: products array is missing or malformed.');
        }
  
        const fullOutfits = data.products
          .filter(product => product.slug === 'full-outfit')
          .flatMap(product =>
            product.product_colors.map(color => {
              const priceInfo = getProductPrice(color);
              return {
                image: color.images[0]?.image,
                name: product.name,
                price: priceInfo.amount,
                currency: priceInfo.currency,
                slug: product.slug,
                url: `https://saarconcept.net/productDetail/${product.slug}?color=${encodeURIComponent(color.color.name)}`,
                color: color.color.name,
                product_color_id: color.id,
                is_favorite: color.is_favorite // Include the is_favorite field
              };
            })
          );
  
        setOutfits(fullOutfits);
  
        // Log the is_favorite status of each product
        fullOutfits.forEach(outfit => {
          console.log(`Product: ${outfit.name}, Color: ${outfit.color}, Is Favorite: ${outfit.is_favorite}`);
        });
      } catch (error) {
        console.error('Error fetching outfits:', error);
        toast.error('An error occurred while fetching outfit data.');
      }
    };
  
    fetchOutfits();
  }, [userLocation]);
  

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        console.error('No refresh token found');
        navigate('/login'); // Redirect to login if no refresh token
        return;
      }
      const response = await fetch('https://saarconcept.net/api/token/refresh/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error('Failed to refresh token');
      }
      const data = await response.json();
      localStorage.setItem('access_token', data.access); // Save the new access token
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error.message);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      navigate('/login'); // Redirect to login on failure
    }
  };
  const addToFavorites = async (productColorId) => {
    let token = localStorage.getItem('access_token');
    
    if (!token) {
      toast.error('Please log in to manage favorites.');
      return;
    }
  
    try {
      const makeApiRequest = async () => {
        const method = outfits[centerOutfit]?.is_favorite ? 'DELETE' : 'POST';
        const url = method === 'DELETE' 
          ? `https://saarconcept.net/api/favorites/?product_color_id=${productColorId}`
          : 'https://saarconcept.net/api/favorites/';
  
        const options = {
          method,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
  
        if (method === 'POST') {
          options.body = JSON.stringify({ product_color_id: productColorId });
        }
  
        const response = await fetch(url, options);
        return response;
      };
  
      let response = await makeApiRequest();
  
      // If the token is expired, try refreshing it
      if (response.status === 401) {
        token = await refreshAccessToken();
        if (token) {
          response = await makeApiRequest();
        } else {
          toast.error('Session expired. Please log in again.');
          return;
        }
      }
  
      if (response.ok) {
        // Update the local state to toggle is_favorite
        const updatedOutfits = outfits.map((outfit, index) => 
          index === centerOutfit 
            ? { ...outfit, is_favorite: !outfit.is_favorite } 
            : outfit
        );
        setOutfits(updatedOutfits);
  
        // Show appropriate toast message
        if (outfits[centerOutfit]?.is_favorite) {
      
        } else {
       
          window.location.href = 'https://saarconcept.net/favorites';
        }
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.detail || 'Unable to manage favorites'}`);
      }
    } catch (error) {
      console.error('Error managing favorites:', error);
      toast.error('An error occurred while managing favorites.');
    }
  };
const handleFavoriteClick = () => {
  const selectedOutfit = outfits[centerOutfit];
  if (!selectedOutfit) {
    toast.error('No outfit selected!');
    return;
  }

  console.log('Selected Outfit:', selectedOutfit); // Debugging

  if (!selectedOutfit.product_color_id) {
    console.error('Product Color ID is missing!');
    toast.error('Unable to add to favorites: Missing Product Color ID.');
    return;
  }

  addToFavorites(selectedOutfit.product_color_id);
};

  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    JPY: '¥',
    AUD: 'A$',
    CAD: 'C$',
    TRY: '₺',
    IQD: 'IQD',
  };

  const formatPrice = (price, currency) => {
    if (!price || !currency) return '';
    const symbol = currencySymbols[currency] || currency;
    if (currency === 'TRY') {
      return `${price}${symbol}`;
    }
    return `${symbol}${price}`;
  };

  const getUserLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setUserLocation(data.country_code);
    } catch (error) {
      console.error('Error fetching location:', error);
      setUserLocation('GLOBAL');
    }
  };

  const getProductPrice = (color) => {
    if (!color.country_prices || !userLocation) {
      return {
        amount: color.base_price,
        currency: 'USD'
      };
    }

    const locationPrice = color.country_prices.find(
      price => price.country === userLocation
    );

    if (locationPrice) {
      return {
        amount: locationPrice.price_amount,
        currency: locationPrice.price_currency
      };
    }

    return {
      amount: color.base_price,
      currency: 'USD'
    };
  };
  

  
 

  const addToCart = () => {
    const selectedOutfit = outfits[centerOutfit];
    if (selectedOutfit) {
      const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
      const selectedColor = selectedOutfit.color.toLowerCase();
      
      const existingProduct = cartItems.find(
        (item) => item.name === selectedOutfit.name && item.color.toLowerCase() === selectedColor
      );
      
      if (existingProduct) {
        toast.warning('Product is already in the cart!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      cartItems.push({
        image: selectedOutfit.image,
        name: selectedOutfit.name,
        price: selectedOutfit.price,
        currency: selectedOutfit.currency,
        size: 'S',
        slug: selectedOutfit.slug,
        color: selectedOutfit.color,
      });

      localStorage.setItem('cart', JSON.stringify(cartItems));
      toast.success('Product added to cart successfully!');
      window.scrollTo(0, 0);
      navigate('/cart');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  const handleOutfitClick = index => {
    setCenterOutfit(index);
  };

  const handleCenterOutfitClick = () => {
    if (outfits.length > 0) {
      window.location.href = outfits[centerOutfit]?.url;
    }
  };

  const updateMovementDistance = useCallback(() => {
    setMovementDistance(window.innerWidth < 768 ? 100 : 170);
  }, []);

  useEffect(() => {
    updateMovementDistance();
    window.addEventListener('resize', updateMovementDistance);
    return () => window.removeEventListener('resize', updateMovementDistance);
  }, [updateMovementDistance]);

  if (outfits.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div id="full-body-outfit" className="flex flex-col items-center py-0 bg-[#FDF5F2]">
      <ToastContainer />
      <h1 className="text-2xl md:text-3xl font-gothamBold text-[#3D291D] mb-16 text-center">
        BUY FULL OUTFIT NOW!
      </h1>
      <div className="relative flex flex-col items-center w-full max-w-screen-lg h-[400px] md:h-[600px] bg-[#FDF5F2] px-4 md:px-0">
        <div className="relative flex justify-center items-center w-full h-full mb-8">
          {outfits.map((outfit, index) => {
            const isCenter = index === centerOutfit;
            const isLeft = index === (centerOutfit - 1 + outfits.length) % outfits.length;
            const isRight = index === (centerOutfit + 1) % outfits.length;

            return (
              <motion.img
                key={index}
                src={outfit.image}
                alt={outfit.name}
                className={`absolute object-cover rounded-lg cursor-pointer ${isCenter ? 'z-20' : 'z-10'} w-[150px] h-[300px] md:w-[250px] md:h-[500px]`}
                initial={{ scale: 0.8 }}
                animate={{
                  scale: isCenter ? 1.2 : 0.8,
                  x: isCenter ? 0 : isLeft ? `-${movementDistance}px` : isRight ? `${movementDistance}px` : '0',
                }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                onClick={isCenter ? handleCenterOutfitClick : () => handleOutfitClick(index)}
              />
            );
          })}
        </div>
        <div className="relative flex flex-col items-center justify-center w-full mt-4 bg-[#FDF5F2]">
          <p className="text-xl md:text-3xl font-gothamBold text-[#3D291D] text-center mt-4">
            {outfits[centerOutfit]?.color} {outfits[centerOutfit]?.name}
          </p>
     
 {/* Mobile Structure */}
<div className="flex md:hidden justify-center items-center gap-x-10">
  <span className="text-sm font-gothamLight text-[#3D291D]">
    {formatPrice(outfits[centerOutfit]?.price, outfits[centerOutfit]?.currency)}
  </span>
  <span className="text-xl font-gothamBold text-[#7DDED9]">
    {formatPrice(
      (outfits[centerOutfit]?.price * 0.8).toFixed(2), // Apply 20% discount and fix to 2 decimals
      outfits[centerOutfit]?.currency
    )}
  </span>
</div>

{/* Desktop Structure */}
<div className="hidden md:flex justify-center items-center gap-x-16">
  <span className="text-md font-gothamLight text-[#3D291D]">
    {formatPrice(outfits[centerOutfit]?.price, outfits[centerOutfit]?.currency)}
  </span>
  <span className="text-2xl font-gothamBold text-[#7DDED9]">
    {formatPrice(
      (outfits[centerOutfit]?.price * 0.8).toFixed(2), // Apply 20% discount and fix to 2 decimals
      outfits[centerOutfit]?.currency
    )}
  </span>
</div>



{/* Mobile Structure */}
<div className="flex justify-center items-center w-full mt-2 md:hidden">
  <button
    onClick={addToCart}
    className="px-4 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm mr-16 mt-2"
  >
    Add to Cart
  </button>
  <span
    onClick={handleFavoriteClick}
    className="text-2xl font-gothamBold text-[#FA8F21] mt-2"
  >
    {outfits[centerOutfit]?.is_favorite ? <FaHeart /> : <LuHeart />}
  </span>
</div>

{/* Desktop Structure */}
<div className="hidden md:flex justify-center items-center w-full mt-2">
  <button
    onClick={addToCart}
    className="px-4 md:px-6 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm md:text-lg mr-16"
  >
    Add to Cart
  </button>
  <span
    onClick={handleFavoriteClick}
    className="text-2xl md:text-3xl font-gothamBold text-[#FA8F21]"
  >
    {outfits[centerOutfit]?.is_favorite ? <FaHeart /> : <LuHeart />}
  </span>
</div>


</div>
      </div>
      <LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <hr className="w-full border-t-2 border-[#3D291D] mt-20 md:mt-32" />
    </div>
  );
};

export default FullBodyOutfit;