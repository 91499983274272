import React, { useState, useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Navbar3 from '../components/navbarbrown';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Spinner = () => (
  <div className="flex justify-center items-center w-full h-full">
    <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

const Favorite = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userLocation, setUserLocation] = useState('GLOBAL');
  const navigate = useNavigate();

  const token = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  const currencySymbols = {
    USD: '$',
    TRY: '₺',
    IQD: 'IQD',
    EUR: '€',
    GBP: '£',
  };

  const handleAddToCart = (favorite) => {
    const productColor = favorite.product_color;
  
    const { amount, currency } = getProductPrice(productColor);
  
    const cartItem = {
      name: productColor.product_name,
      color: productColor.color?.name,
      price: amount,
      currency: currency,
      images: productColor.images,
      image: productColor.images?.[0]?.image,
      slug: productColor.slug,
      size: "S",
      selectedSizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 0 },
        { size: "L", quantity: 0 }
      ]
    };
  
    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
    
    const isProductInCart = existingCart.some(item => item.slug === cartItem.slug && item.color === cartItem.color);
  
    if (isProductInCart) {
      toast.warn('This product is already in your cart!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    const updatedCart = [...existingCart, cartItem];
    
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    navigate('/cart');
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        console.error('No refresh token found');
        navigate('/login');
        return;
      }
      const response = await fetch('https://saarconcept.net/api/token/refresh/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error('Failed to refresh token');
      }
      const data = await response.json();
      localStorage.setItem('access_token', data.access);
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error.message);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      navigate('/login');
    }
  };

  const handleDelete = async (productColorId) => {
    try {
      const deleteUrl = `https://saarconcept.net/api/favorites/?product_color_id=${productColorId}`;
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Error response:', errorData);
        throw new Error('Failed to delete favorite');
      }

      setFavorites((prevFavorites) =>
        prevFavorites.filter(favorite => favorite.product_color.id !== productColorId)
      );
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };

  const fetchFavorites = async () => {
    try {
      let currentToken = token;
      const response = await fetch('https://saarconcept.net/api/favorites/', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        currentToken = await refreshAccessToken();
        const retryResponse = await fetch('https://saarconcept.net/api/favorites/', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${currentToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (!retryResponse.ok) {
          throw new Error(`Failed to fetch favorites after token refresh: ${retryResponse.status}`);
        }
        const data = await retryResponse.json();
        setFavorites(data);
      } else if (!response.ok) {
        throw new Error(`Failed to fetch favorites: ${response.status} ${response.statusText}`);
      } else {
        const data = await response.json();
        setFavorites(data);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error.message);
      if (error.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchFavorites();
  }, [navigate, token]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserLocation(data.country_code);
      } catch (error) {
        console.error('Error fetching location:', error);
        setUserLocation('GLOBAL');
      }
    };

    getUserLocation();
  }, []);

  const getProductPrice = (color) => {
    if (!color.country_prices || !userLocation) {
      return {
        amount: color.base_price,
        currency: 'USD',
      };
    }

    const locationPrice = color.country_prices.find(
      (price) => price.country === userLocation
    );

    if (locationPrice) {
      return {
        amount: locationPrice.price_amount,
        currency: locationPrice.price_currency,
      };
    }

    return {
      amount: color.base_price,
      currency: 'USD',
    };
  };

  const renderFavoriteItem = (favorite, isMobileView) => {
    const productColor = favorite.product_color;
    const imageUrl = productColor?.images?.[0]?.image || '/assets/default-image.jpg';
    const colorName = productColor?.color?.name || 'Color not available';
    const productName = productColor?.product_name || 'Product name not available';
    const { amount, currency } = getProductPrice(productColor);
    const slug = productColor?.product_slug || productName.toLowerCase().replace(/\s+/g, '-');
    return (
      <>
        <div
          className="relative flex flex-col items-center w-full h-auto bg-transparent border-1 border-[#3D291D] p-4 cursor-pointer"
          onClick={() => navigate(`/productDetail/${slug}?color=${colorName}`)}
        >
          {/* Square Box */}
          <div className={`relative ${isMobileView ? 'w-40 h-40' : 'w-48 h-48 xl:w-64 xl:h-64 3xl:w-112 3xl:h-112'}`}>
            <img
              src={imageUrl}
              alt={productName}
              className="object-cover w-full h-full"
            />
            <div className="absolute bottom-2 right-2">
              <FaHeart
                className="text-[#FA8F21] text-xl xl:text-2xl 3xl:text-3xl cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from triggering the item navigation
                  handleDelete(productColor.id);
                }}
              />
            </div>
          </div>
  
          {/* Product Info Section */}
          <div className="w-full flex flex-col items-start mt-4">
            {/* Name and Price Side by Side */}
            <div className="w-full flex justify-between items-center mt-4 sm:space-x-4 xl:space-x-6 3xl:space-x-10">
              <p className="font-bold text-sm sm:text-xs font-gothamBold text-[#3D291D] xl:text-sm 3xl:text-2xl">
                {colorName} {productName}
              </p>
              <p className="font-gothamLight text-xs sm:text-xxs text-[#3D291D] xl:text-xs 3xl:text-xl">
                {amount} {currencySymbols[currency] || ''}
              </p>
            </div>
  
            <button
              onClick={() => handleAddToCart(favorite)}
              className="bg-[#FA8F21] text-white rounded-3xl px-3 py-1 mt-2 font-gothamBold text-xxs sm:px-3 sm:py-2 sm:text-xs md:px-4 md:py-2 md:text-sm xl:text-base 3xl:text-lg"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </>
    );
  };
  

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#FDF5F2]">
        <Spinner />
      </div>
    );
  }
  

  return (
    <div className="bg-[#FDF5F2] sm:px-4 md:px-4 lg:px-4 xl:px-8 2xl:px-8 3xl:px-40 py-0 mb-4">
      <Navbar3 />
      <ToastContainer />
      <div className="flex flex-col items-center mb-4">
        <h1 className="text-3xl md:text-4xl font-bold font-gothamBold text-[#3D291D]">Favorites</h1>
      </div>
      <div className={`grid ${isMobile ? 'gap-3 grid-cols-2 px-3' : 'gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4'}`}>

        {favorites.map((favorite) => renderFavoriteItem(favorite, isMobile))}
      </div>
    </div>
  );
};

export default Favorite;
